'use client'

import { TextArea } from '@/app/_primitives/TextArea'
import { TextField } from '@/app/_primitives/TextField'
import { cn } from '@/app/_primitives/utils/cn'
import React, { createContext, Fragment, useContext, useMemo } from 'react'

type Props = {
  variant?: 'default' | 'dark'
  label: React.ReactNode
  error?: boolean
  className?: string
  required?: boolean
} & React.LabelHTMLAttributes<HTMLLabelElement>

const InputLabelContext = createContext<{
  variant?: Props['variant']
  error?: Props['error']
}>({
  variant: 'default',
  error: false,
})

function Adornment({
  children,
  customChild,
  className,
  position,
}: {
  position: 'start' | 'end' | 'description'
  className?: string
  children: React.ReactNode
  customChild?: boolean
}) {
  const { variant, error } = useContext(InputLabelContext)

  const dataProps = {
    'data-variant': variant,
    'data-error': error,
    'data-position': position,
  }

  if (customChild)
    return (
      <div {...dataProps} className={className}>
        {children}
      </div>
    )

  if (position === 'description')
    return (
      <div
        {...dataProps}
        className={cn(
          'c-b4',
          {
            'text-black': variant === 'dark',
            'text-white': variant === 'default',
          },
          className,
        )}>
        {children}
      </div>
    )

  if (position === 'end')
    return (
      <div
        className={cn(
          'c-b4',
          {
            'text-[#80A8E799]': variant === 'default',
            'text-[#8B8B8B]': variant === 'dark',

            'text-[#FF2B2B]': error && variant === 'dark',
            'text-[#FF9840]': error && variant === 'default',
          },
          className,
        )}>
        {children}
      </div>
    )

  if (position === 'start')
    return (
      <div
        {...dataProps}
        className={cn(
          'c-b4',
          {
            'text-black': variant === 'dark',
            'text-white': variant === 'default',

            'text-[#FF2B2B]': error && variant === 'dark',
            'text-[#FF9840]': error && variant === 'default',
          },
          className,
        )}>
        {children}
      </div>
    )
}

const MemoizedAdornment = React.memo(Adornment)

export function InputLabel({
  variant = 'default',
  label,
  children,
  className,
  error,
  required,
  ...props
}: Props) {
  const slots = useMemo(() => {
    let startAdornment: React.ReactNode = null
    let endAdornment: React.ReactNode = null
    let description: React.ReactNode = null
    const content: React.ReactNode[] = []

    const childArray = !React.isValidElement(children)
      ? Array.isArray(children)
        ? children
        : []
      : children.type === Fragment
        ? children.props.children
        : children

    React.Children.forEach(childArray, (child) => {
      if (!React.isValidElement(child)) return

      if (child.type === MemoizedAdornment) {
        const position = (child.props as any).position || 'start'
        if (position === 'start') startAdornment = child
        else if (position === 'end') {
          endAdornment = child
        } else description = child
      } else if (child.type === TextArea || child.type === TextField) {
        content.push(
          React.cloneElement(child, {
            variant,
            error,
          } as any),
        )
      } else {
        content.push(child)
      }
    })

    return {
      startAdornment,
      endAdornment,
      description,
      content,
    }
  }, [children, variant, error])

  const contextVal = useMemo(
    () => ({
      variant,
      error,
    }),
    [variant, error],
  )

  return (
    <InputLabelContext.Provider value={contextVal}>
      <InputLabelContext.Consumer>
        {(value) => (
          <label
            className={cn('flex flex-col items-stretch gap-8', className)}
            {...props}>
            <div className={'flex flex-col gap-6'}>
              <span
                className={cn('c-h7', {
                  'text-black': value.variant === 'dark',
                  'text-white': value.variant === 'default',
                })}>
                {label}
                {required && (
                  <>
                    {' '}
                    <span className="c-h7 text-text-warning-inverse">*</span>
                  </>
                )}
              </span>
              {slots.description}
            </div>
            {slots.content.map((child, idx) => (
              <Fragment key={idx}>{child}</Fragment>
            ))}
            {slots.startAdornment && slots.endAdornment && (
              <div className={'flex flex-row items-center justify-between'}>
                {slots.startAdornment}
                {slots.endAdornment}
              </div>
            )}

            {!slots.startAdornment && slots.endAdornment && (
              <div className={'flex flex-row items-center justify-end'}>
                {slots.endAdornment}
              </div>
            )}

            {slots.startAdornment && !slots.endAdornment && (
              <div className={'flex flex-row items-center justify-start'}>
                {slots.startAdornment}
              </div>
            )}
          </label>
        )}
      </InputLabelContext.Consumer>
    </InputLabelContext.Provider>
  )
}

InputLabel.Adornment = MemoizedAdornment
